//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
Swiper.use([Navigation, Pagination, Scrollbar]);

import ImageCopyright from '../Partials/ImageCopyright.vue';

export default {
    name: 'ImageSelectionGallery',
    components: { ImageCopyright },
    props: ['data'],
    computed: {
        slider() {
            return this.data.slider?.map((slide) => {
                return [1, 2, 3].map((i) => {
                    const image = slide?.[`image${i}`]?.image;

                    if (!image) return null;

                    return {
                        index: i,
                        ...this.$mapImageData(image),
                        caption: slide?.[`imageCaption${i}`],
                    };
                });
            });
        },
    },
    mounted() {
        const gallery = new Swiper('.image-selection-gallery__slider--slide', {
            spaceBetween: 5,
            slidesPerView: 'auto',
            loop: false,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            speed: 750,
            a11y: true,
            breakpoints: {
                768: {
                    spaceBetween: 30,
                },
            },
            navigation: {
                nextEl: '.image-selection-gallery__button--next',
                prevEl: '.image-selection-gallery__button--prev',
                disabledClass: 'image-selection-gallery__button--disabled',
                dragSize: 100,
            },
            pagination: {
                el: '.image-selection-gallery__pagination',
                type: 'fraction',
            },
            scrollbar: {
                el: '.image-selection-gallery__scrollbar',
                dragClass: 'image-selection-gallery__scrollbar--drag',
                hide: false,
            },
            on: {
                init: function () {
                    showNavigation(this);
                },
                resize: function () {
                    showNavigation(this);
                },
            },
        });

        function showNavigation(swiper) {
            const isBeginning = swiper.isBeginning;
            const isEnd = swiper.isEnd;

            if (isBeginning && isEnd) {
                swiper.el.classList.add('image-selection-gallery__slider--no-navigation');
            } else {
                swiper.el.classList.remove('image-selection-gallery__slider--no-navigation');
            }
        }
    },
};
